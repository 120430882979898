import { createReducer } from 'typesafe-actions';
import { authenticationAction, authenticationState } from './types';

import {
  SET_LOGIN,
  SET_LOGIN_ERROR,
  SET_LOGIN_SUCCESS,
  GET_CHECK_SESSION,
  GET_CHECK_SESSION_SUCCESS,
  GET_CHECK_SESSION_ERROR,
  SET_LOGOUT,
  SET_LOGOUT_SUCCESS,
  SET_LOGOUT_ERROR,
  GET_IS_AIVORY_AVAILABLE,
  GET_IS_AIVORY_AVAILABLE_SUCCESS,
  GET_IS_AIVORY_AVAILABLE_ERROR,
  GET_VODA_HEALTH,
  GET_VODA_HEALTH_SUCCESS,
  GET_VODA_HEALTH_ERROR,
  SET_ERROR_NULL_AUTH,
  CHECK_COOKIE,
  GET_VODA_SESSION_HEALTH,
  GET_VODA_SESSION_HEALTH_SUCCESS,
  GET_VODA_SESSION_HEALTH_ERROR,
  GET_PV_USAGE_ERROR,
  GET_PV_USAGE,
  GET_PV_USAGE_SUCCESS,
  GET_EXPORT_EXCEL,
  GET_EXPORT_EXCEL_ERROR,
  GET_EXPORT_EXCEL_SUCCESS,
  GET_CHECK_SCRIPT,
  GET_CHECK_SCRIPT_ERROR,
  GET_CHECK_SCRIPT_SUCCESS,
  SET_TOGGLE_CHECK,
} from './actions';

function getCookie(cookieName: string) {
  let cookieValue = null;
  if (document.cookie) {
    const array = document.cookie.split(`${escape(cookieName)}=`);
    if (array.length >= 2) {
      const arraySub = array[1].split(';');
      cookieValue = unescape(arraySub[0]);
    }
  }
  return cookieValue;
}

const deleteCookie = (
  sKey: string,
  sDomain: string | null,
  sPath: string | null
) => {
  if (getCookie(sKey)) {
    document.cookie = `${sKey}=${sPath ? `;path=${sPath}` : ''}${
      sDomain ? `;domain=${sDomain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
};

const initialState: authenticationState = {
  isLogin: false,
  isExistsScript: false,
  loginLoading: false,
  isAIVORYAvailable: false,
  setLoginError: null,
  getCheckSessionError: null,
  getIsAivoryAvailableError: null,
  setLogoutError: null,
  setLoginErrorMessage: {
    '4000100':
      'ID 또는 비밀번호를 확인해주세요.\n5회 이상 잘못 입력시 계정이 잠깁니다.',
    '4000101':
      '비밀번호를 5회 이상 잘못 입력하여 계정이 잠겼습니다.\n관리자에게 문의하세요.',
    network: '네트워크 연결을 확인해주세요.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  getCheckSessionErrorMessage: {
    '4010000': '로그인 시간이 만료되었습니다.\n다시 로그인 해주세요.',
    '4010001': '다른기기에서 로그인 하였습니다.\n다시 로그인 해주세요.',
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default:
      '일시적인 오류로 일부 기능에 오류가 발생했습니다.\n지속적인 문제 발생시 관리자에게 문의하세요.',
  },
  setLogoutMessage: {
    network: '네트워크 연결 오류로 로그아웃 되었습니다.',
    default: '정상적으로 로그아웃되었습니다.',
  },

  userId: '',
  userTenant: '',
  isVodaAvailable: false,
  created_time: '',
  isLogout: false,
  vodaHealthProgress: 0,
  user_name: '',
  user_email: '',
  session_time: 3600,
  isCookie: false,
  isSessionValid: null,
  sessionValidTime: null,
  pvUsageData: null,
  isLoadingExcel: false,
  isloadingCheckScript: false,
  isCheck: false,
};

const reducer = createReducer<authenticationState, authenticationAction>(
  initialState,
  {
    [SET_LOGIN]: (state) => ({
      ...state,
      loginError: null,
      loginLoading: true,
      setLoginError: null,
      isLogout: null,
    }),
    [SET_LOGIN_SUCCESS]: (state, action) => {
      localStorage.setItem('voda_tenant', action.payload.payload.tenant);
      localStorage.setItem(
        'voda_script',
        action.payload.payload.exists.toString()
      );
      return {
        ...state,
        isLogin: true,
        isExistsScript: action.payload.payload.exists,
        loginLoading: false,
        setLoginError: null,
        userId: action.payload.payload.user_id,
        userTenant: action.payload.payload.tenant,
        created_time: action.payload.payload.created_time,
        user_name: action.payload.payload.user_name,
        user_email: action.payload.payload.email,
        isLogout: false,
      };
    },
    [SET_LOGIN_ERROR]: (state, action) => ({
      ...state,
      isLogin: false,
      isExistsScript: false,
      loginLoading: false,
      setLoginError: action.payload,
      loginCode: 'network error',
    }),

    [GET_CHECK_SESSION]: (state) => ({
      ...state,
      loginLoading: true,
      isLogout: null,
    }),
    [GET_CHECK_SESSION_SUCCESS]: (state, action) => {
      if (localStorage.getItem('voda_script') === 'true') {
        return {
          ...state,
          isLogin: true,
          loginLoading: false,
          getCheckSessionError: null,
          userId: action.payload.payload.user_id,
          created_time: action.payload.payload.created_time,
          user_name: action.payload.payload.user_name,
          user_email: action.payload.payload.email,
          isLogout: false,
          isExistsScript: true,
        };
      }
      return {
        ...state,
        isLogin: true,
        loginLoading: false,
        getCheckSessionError: null,
        userId: action.payload.payload.user_id,
        created_time: action.payload.payload.created_time,
        user_name: action.payload.payload.user_name,
        user_email: action.payload.payload.email,
        isLogout: false,
      };
    },
    [GET_CHECK_SESSION_ERROR]: (state, action) => {
      localStorage.removeItem('voda_tenant');
      localStorage.removeItem('voda_script');
      deleteCookie('sessionid', '.nerdfactory.ai', '/');
      deleteCookie('csrftoken', '.nerdfactory.ai', '/');
      return {
        ...state,
        isLogin: false,
        loginLoading: false,
        getCheckSessionError: action.payload,
      };
    },

    [SET_LOGOUT]: (state) => ({
      ...state,
      isLogin: false,
      loginLoading: true,
      isLogout: null,
    }),
    [SET_LOGOUT_SUCCESS]: (state) => {
      deleteCookie('sessionid', '.nerdfactory.ai', '/');
      deleteCookie('csrftoken', '.nerdfactory.ai', '/');
      localStorage.removeItem('voda_tenant');
      localStorage.removeItem('voda_script');
      return {
        ...state,
        isLogin: false,
        loginLoading: false,
        setLogoutError: null,
        isLogout: true,
      };
    },
    [SET_LOGOUT_ERROR]: (state, action) => {
      deleteCookie('sessionid', '.nerdfactory.ai', '/');
      deleteCookie('csrftoken', '.nerdfactory.ai', '/');
      localStorage.removeItem('voda_tenant');
      localStorage.removeItem('voda_script');
      return {
        ...state,
        isLogin: false,
        loginLoading: false,
        setLogoutError: action.payload,
        isLogout: true,
      };
    },

    [GET_IS_AIVORY_AVAILABLE]: (state) => ({
      ...state,
      isAIVORYAvailable: false,
    }),
    [GET_IS_AIVORY_AVAILABLE_SUCCESS]: (state) => ({
      ...state,
      isAIVORYAvailable: true,
      getIsAivoryAvailableError: null,
    }),
    [GET_IS_AIVORY_AVAILABLE_ERROR]: (state, action) => ({
      ...state,
      isAIVORYAvailable: false,
      getIsAivoryAvailableError: action.payload,
    }),

    [GET_VODA_HEALTH]: (state) => ({
      ...state,
      isVodaAvailable: false,
    }),
    [GET_VODA_HEALTH_SUCCESS]: (state) => ({
      ...state,
      isVodaAvailable: true,
    }),
    [GET_VODA_HEALTH_ERROR]: (state) => ({
      ...state,
      isVodaAvailable: false,
    }),

    [GET_VODA_SESSION_HEALTH]: (state) => ({
      ...state,
      isSessionValid: null,
      sessionValidTime: null,
    }),
    [GET_VODA_SESSION_HEALTH_SUCCESS]: (state, action) => ({
      ...state,
      isSessionValid: action.payload.payload.isValid,
      sessionValidTime: action.payload.payload.time,
    }),
    [GET_VODA_SESSION_HEALTH_ERROR]: (state, action) => ({
      ...state,
      isSessionValid: false,
      sessionValidTime: 0,
      getIsAivoryAvailableError: action.payload,
    }),

    [GET_PV_USAGE]: (state) => ({
      ...state,
      pvUsageData: null,
    }),

    [GET_PV_USAGE_SUCCESS]: (state, action) => ({
      ...state,
      pvUsageData: action.payload.payload,
    }),

    [GET_PV_USAGE_ERROR]: (state) => ({
      ...state,
      pvUsageData: null,
    }),

    [GET_EXPORT_EXCEL]: (state) => ({
      ...state,
      isLoadingExcel: true,
    }),

    [GET_EXPORT_EXCEL_SUCCESS]: (state) => ({
      ...state,
      isLoadingExcel: false,
    }),

    [GET_EXPORT_EXCEL_ERROR]: (state) => ({
      ...state,
      isLoadingExcel: false,
    }),

    [GET_CHECK_SCRIPT]: (state) => ({
      ...state,
      isloadingCheckScript: true,
      isExistsScript: false,
      isCheck: false,
    }),

    [GET_CHECK_SCRIPT_SUCCESS]: (state, action) => {
      localStorage.setItem(
        'voda_script',
        action.payload.payload.script.toString()
      );
      return {
        ...state,
        isloadingCheckScript: false,
        isCheck: true,
        isExistsScript: action.payload.payload.script,
      };
    },

    [GET_CHECK_SCRIPT_ERROR]: (state) => ({
      ...state,
      isloadingCheckScript: false,
      isCheck: false,
      isExistsScript: false,
    }),

    [SET_TOGGLE_CHECK]: (state) => ({
      ...state,
      isCheck: false,
    }),

    [SET_ERROR_NULL_AUTH]: (state) => ({
      ...state,
      setLoginError: null,
      getCheckSessionError: null,
      setLogoutError: null,
      getIsAivoryAvailableError: null,
    }),

    [CHECK_COOKIE]: (state, action) => ({
      ...state,
      isCookie: action.payload,
    }),
  }
);

export default reducer;
